<template lang="pug">
.transfer
  el-row
    el-col(:span="12")
    el-col(:span="12")
      el-button(type="primary", @click="toForm") 发起转账
  el-table(:data="logs", stripe)
    el-table-column(label="更新日期")
      template(slot-scope="scope")
        span {{$moment(scope.row.update_date).calendar()}}
    el-table-column(prop="payee_info_name", label="收款方")
    el-table-column(prop="payee_info_identity", label="收款方账号")
    el-table-column(label="商品编码")
      template(slot-scope="scope")
        span {{scope.row.goods_code || '-'}}
    el-table-column(prop="desc", label="描述")
    el-table-column(prop="out_biz_no", label="订单号")
    el-table-column(label="渠道")
      template(slot-scope="scope")
        span {{scope.row.channel || '-'}}
    el-table-column(prop="trans_date", label="转账时间")
      template(slot-scope="scope")
        span {{scope.row.trans_date ? $moment(scope.row.trans_date).calendar() : '-'}}
    el-table-column(prop="status", label="转账状态")
      template(slot-scope="scope")
        el-tag(v-if="scope.row.status === 'SUCCESS'", type="success") 成功
        el-tag(v-else, type="info") {{ scope.row.status }}
    el-table-column(prop="trans_amount", label="转账金额（元）")
    el-table-column(label="订单查询时间范围")
      template(slot-scope="scope")
        span(v-if="scope.row.query_start_time") {{$moment(scope.row.query_start_time).format('YYYY-MM-DD HH:mm:ss')}} ~ {{$moment(scope.row.query_end_time).format('YYYY-MM-DD HH:mm:ss')}}
        span(v-else) -
  el-pagination(
    background,
    :current-page="pageModel.pageNo",
    layout="prev, pager, next, total",
    :total="pageModel.total"
    @current-change="query"
  )
  el-dialog(title="发起转账", :visible.sync="dialogFormVisible", width="400px")
    el-form(:model="form")
      el-form-item(label="转账金额")
        el-input(v-model="form.amount", autocomplete="off")
      el-form-item(label="收款账号")
        el-select(v-model="form.account" placeholder="请选择收款账号")
          el-option(label="中国移动通信集团新疆有限公司" value="中国移动通信集团新疆有限公司")
    div(slot="footer")
      el-button(@click="dialogFormVisible = false") 取 消
      el-popconfirm(title="确认转账吗？" @confirm="transfer")
        el-button(type="primary" slot="reference") 确 定
</template>

<script>

export default {
  data() {
    return {
      dialogFormVisible: false,
      logs: [],
      modifyGood: {},
      modifySortGood: '',
      form: {
        amount: 0,
        account: '中国移动通信集团新疆有限公司'
      },
      pageModel: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
        condition: {
        }
      }
    }
  },
  mounted() {
    this.query(1)
  },
  methods: {
    query(pageNo) {
      this.pageModel.pageNo = pageNo || this.pageModel.pageNo
      let _condition = {}
      this.$service.TransferService
        .query({
          pageNo: this.pageModel.pageNo,
          pageSize: this.pageModel.pageSize,
          condition: _condition
        })
        .then(res => {
          if (res.status === 201) {
            this.pageModel.total = res.data.data.total
            this.logs = res.data.data.logs
          }
        })
    },
    toForm() {
      this.form.amount = 0
      this.dialogFormVisible = true
    },
    transfer() {
      if(isNaN(this.form.amount) || this.form.amount <= 0) {
        this.$message({
          message: '请输入正确金额',
          type: 'warning'
        })
        return
      }
      this.dialogFormVisible = false
      this.$service.TransferService
        .transfer({
          amount: this.form.amount
        })
        .then(res => {
          if (res.status === 200 && res.data.code === 0) {
            this.$message({
              message: '已提交转账',
              type: 'success'
            })
            this.query(1)
          }
        })
    }
  }
}
</script>

<style lang="less" scope>
.cover-image{
  width: 60px;
  height: 40px;
}
.link{
  cursor: pointer;
}
</style>