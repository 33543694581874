export default {
  init(vue) {
    return {
      getIP() {
        return vue.$http({
          method: 'GET',
          url: '/api/v1/common/ip'
        })
      },
      getDict(params) {
        return vue.$http({
          method: 'GET',
          url: '/api/v1/common/dict',
          params: params
        })
      }
    }
  }
}
