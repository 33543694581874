<template lang="pug">
.recharge-center-goods
  el-row
    el-col(:span="12")
      el-form(:inline="true")
        el-form-item(label="上架状态")
          el-select(v-model="pageModel.condition.status", @change="query(1)")
            el-option(label="全部", value="all")
            el-option(label="已上架", value="1")
            el-option(label="已下架", value="0")
    el-col(:span="12")
      el-button(type="primary", @click="toForm") 添加商品
  el-table(:data="goodsList", stripe)
    el-table-column(type="expand")
      template(slot-scope="scope")
        el-row
          el-col
            h3 摘要描述
            div(v-html="scope.row.summary_desc")
          el-col
            h3 描述
            div(v-html="scope.row.desc")
          el-col
            h3 业务参数
            div {{scope.row.biz_params}}
    el-table-column(label="更新日期")
      template(slot-scope="scope")
        span {{$moment(scope.row.update_date).calendar()}}
    el-table-column(prop="title", label="标题（商品名）")
    el-table-column(prop="pay_type_desc", label="支付类型")
    el-table-column(prop="goods_type_desc", label="商品类别")
    el-table-column(prop="price", label="价格")
    el-table-column(prop="sort", label="排序")
    el-table-column(label="操作")
      template(slot-scope="scope")
        el-popconfirm(v-if="scope.row.status === 1", title="确定下架？", @onConfirm="handleUpdateStatus(scope.row, 0)")
          el-button(slot="reference", type="primary", size="mini") 下架
        el-popconfirm(v-else, title="确定上架？", @onConfirm="handleUpdateStatus(scope.row, 1)")
          el-button(slot="reference", type="warning", size="mini") 上架
        el-button(type="primary", size="mini", @click="toForm(scope.row)") 编辑
  el-pagination(
    background,
    :current-page="pageModel.pageNo",
    layout="prev, pager, next, total",
    :total="pageModel.total"
    @current-change="query"
  )
</template>

<script>

export default {
  data() {
    return {
      goodsList: [],
      modifyGood: {},
      modifySortGood: '',
      pageModel: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
        condition: {
          status: 'all'
        }
      }
    }
  },
  mounted() {
    this.query(1)
  },
  methods: {
    query(pageNo) {
      this.pageModel.pageNo = pageNo || this.pageModel.pageNo
      let _condition = {}
      if(this.pageModel.condition.status !== 'all') {
        _condition.status = this.pageModel.condition.status
      }
      this.$service.RechargeCenterService
        .query({
          pageNo: this.pageModel.pageNo,
          pageSize: this.pageModel.pageSize,
          condition: _condition
        })
        .then(res => {
          if (res.status === 200) {
            this.pageModel.total = res.data.data.total
            this.goodsList = res.data.data.goods
          }
        })
    },
    handleUpdateStatus(good, status) {
      this.$service.RechargeCenterService
        .updateStatus(good._id, status)
        .then(res => {
          if (res.status === 200 && res.data.code === 0) {
            this.$message({
              message: '已更新上架状态',
              type: 'success'
            })
            this.query()
          }
        })
    },
    toForm(good) {
      this.$router.push({name: 'rechargeCenterForm', query: {id: good ? good._id : undefined }})
    }
  }
}
</script>

<style lang="less" scope>
.cover-image{
  width: 60px;
  height: 40px;
}
.link{
  cursor: pointer;
}
</style>